<template>
  <div class="CmsContentsFiledsList">
    <div id="newsWrap" class="contentsWidth mx-auto mt-4">

      <div class="row col-12">
        <p class="h5">記入項目管理 - 一覧</p>
      </div>

      <div id="serchWrap" class="mt-4">
        <b-row class="mt-2">
          <b-col sm="3" class="inputLabel">
            <label>名称</label>
          </b-col>
          <b-col sm="7">
            <b-form-input v-model="search.name" placeholder="入力してください。"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col sm="3" class="inputLabel">
            <label>入力方法</label>
          </b-col>
          <b-col sm="7">
            <b-form-select v-model="search.type" :options="CONST_FIELD_TYPE">
              <template v-slot:first>
                <option :value="''">-- 未選択 --</option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col sm="3" class="inputLabel">
            <label>グループ</label>
          </b-col>
          <b-col sm="7">
            <b-form-select v-model="search.group" :options="groupList">
              <template v-slot:first>
                <option :value="''">-- 未選択 --</option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>

      </div>

      <div class="mt-4 float-right">
        <b-link to="/cms/contents/fields/create">新規登録</b-link>
      </div>

      <div id="tableWrap" class="mt-2">
        <b-pagination
          aria-controls="fieldTable"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        />

        <b-table striped hover
        id='fieldTable'
        :items="fieldList"
        :fields="header"
        :filter="search"
        :filter-function="filtering"
        @filtered="onFiltered"
        show-empty
        :per-page="perPage"
        :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <p class="h5">記入項目がありません。</p>
          </template>
          <template v-slot:cell(btn)="row">
            <b-button size="sm"
              v-if="row.item.type !== '特殊'"
              :to="'/cms/contents/fields/edit/'+row.item.id">
              編集
            </b-button>
          </template>
        </b-table>
      </div>

    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'CmsContentsFiledsList',
  data() {
    return {
      search: {
        name: '',
        group: '',
        type: '',
      },
      name: '',
      fieldList: [],
      header: [
        { key: 'name', label: '名称', sortable: true },
        { key: 'type', label: '種類', sortable: true },
        { key: 'status', label: '状態', sortable: true },
        { key: 'updated_at', label: '最終更新日', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ],
      groupList: [],
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
    };
  },
  methods: {
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const groupSort = search.group === '' || lineData.groupId === search.group;
      const typeSort = search.type === '' || lineData.typeValue === search.type;

      return nameSort && groupSort && typeSort;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    initFetch() {
      const promiseFuncs = [api.send('/api/contents/fields/list'), api.send('/api/contents/fields/group/list')];
      return api.all(promiseFuncs)
        .then((responses) => {
          this.groupList = responses[1].data.groupList;
          this.fieldList = this.getListItems(responses[0].data.list);
          this.totalRows = this.fieldList.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getListItems(rawList) {
      const listItems = [];
      Object.keys(rawList).forEach((id) => {
        const line = rawList[id];
        listItems.push({
          id,
          name: line.name,
          type: CONST_OPTIONS.FIELD_TYPE[CONST_OPTIONS.FIELD_VALUE[line.type]].text,
          updated_at: line.updated_at,
          groupId: line.group_id,
          typeValue: line.type,
        });
      });
      return listItems;
    },
  },
  computed: {
  },
  // ロード画面
  async created() {
    this.CONST_FIELD_TYPE = CONST_OPTIONS.FIELD_TYPE;
    this.$store.dispatch('page/onLoading');
    await this.initFetch();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>

</style>
